.logout{
    color: #696969;
    font-size: 16px;
    font-weight: smaller;
}
.home{
    font-size: 16px;
    text-decoration: underline;
    font-weight: smaller;
    margin-left: 2rem;
}