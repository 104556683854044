/* .dashboard{
    margin: 1rem;
} */

.sideCards{
    margin-top: 1rem;
}

.cal{
    margin-left: 25rem;
    font-size: medium;
}
ul{
    margin-top: 6rem;
}
li{
    list-style: none;
    margin: 2px;
    margin-left: 7rem;
    font-size: 12px;
}
.totalFeedback1{
    text-align: center;
    font:normal normal bold 26px/30px Roboto;
    
}
.totalFeedback2{ 
    text-align: center;
    font:normal normal bold 16px/20px Roboto;

}
.activeButton
{
    background-color: blue;
}

/* .dashboard::-webkit-scrollbar{
    display: none;
} */
.ant-picker-input>input{
    color: #0086FF;
    font: normal normal medium 16px/21px Roboto;
}
.ant-tabs-tab.ant-tabs-tab-active{
    font:normal normal bold 16px/32px Roboto;
    color:#111111;
    opacity: 1;
}